.formWrapper {
  display: flex;
  justify-content: center;
  padding: 3% 0% 0% 0%;

  &__input {
    padding-top: 4%;
  }
  &__label {
    position: relative;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1%;
    color: #425466;
    padding-bottom: 4px;
  }
  &__input2 {
    padding: 6% 0px;
  }

  &__flexInput {
    display: flex;
    justify-content: space-between;
    padding-top: 5%;
    gap: 15px;
  }
  &__textarea {
    font-family: 'Inter', sans-serif;
    background-color: #ffffff;
    border: 1px solid #c1c6d3;
    border-radius: 8px;
    padding: 17px;
    font-size: 1rem;
    width: 100%;
    height: 106px;
    box-sizing: border-box;

    &::placeholder {
      color: #c1c6d3;
      font-size: 16px;
      line-height: 24px;
    }
  }
  &__forgotPasswordText {
    display: flex;
    justify-content: flex-end;
    position: relative;
    color: #ed9373;
    font-weight: 600;
    font-size: 14px;
    padding: 15px 0px;
    right: -7%;
  }
  &__button {
    display: flex;
    justify-content: right;
  }
  &__btn {
    background: none;
    border: none;
    color: #ed9373;
    font-weight: 500;
    cursor: pointer;
  }

  &__otp {
    text-align: center;
    color: #425466;
    font-size: 14px;
    padding: 20px 0px 30px 0px;
    line-height: 24px;

    &__expire {
      font-weight: 500;
      color: #0b2253;
      text-align: center;
    }
  }

  &__meter {
    width: 400px;
    padding-bottom: 8px;
    color: rgb(232, 10, 10);
    font-size: 14px;
    text-align: justify;

    &__matched {
      color: green;
    }
  }

  &__button {
    padding-top: 6%;
  }
}

.formlayout {
  &__form {
    width: 400px;
    margin: auto;
  }
}
.layout {
  padding: 0px 150px;
  &__referralFormWrapper {
    width: 450px;
    margin: 30px auto;
  }
}

.upload {
  border: 1px dashed #093fce;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 40px;

  &__file {
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__empty_state {
    display: flex;
    align-items: flex-start;

    p {
      margin: 0 0 0 16px;
      display: flex;
      flex-direction: row;
    }

    &__file {
      color: #ed9373;
      font-weight: 600;
      font-size: 16px;
    }

    &_caption {
      font-weight: 400;
      font-size: 16px;
      color: #83889d;
      line-height: 20px;
    }

    &_csv {
      color: #83889d;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      margin-top: 4px !important;
    }
  }
}

.procedureSummary {
  margin-top: 50px;
  &__text {
    font-weight: 500;
    font-size: 14px;
    color: #b5b5bd;
    padding-top: 5px;
  }

  &__amount {
    font-weight: 700;
    font-size: 20px;
    color: #364469;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  &__AddButton {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0px;
    color: #b5b5bd;
  }
}
