.indicator {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2%;

  &.active {
    background: #1aa551;
    box-shadow: 0 0 0 3px #eafbf1;
  }

  &.deactivated {
    background: red;
    box-shadow: 0 0 0 3px #ffdede;
  }

  &.transferred {
    background: purple;
    box-shadow: 0 0 0 3px #ead0e2;
  }

  &.pending {
    background: red;
    box-shadow: 0 0 0 3px #ffdede;
  }

  &.completed {
    background: #1aa551;
    box-shadow: 0 0 0 3px #eafbf1;
  }

  &.confirmed {
    background: #1aa551;
    box-shadow: 0 0 0 3px #eafbf1;
  }
 &.visited {
    background: #093fce;
    box-shadow: 0 0 0 3px #edf2f7;
  }
  &.pending_payment {
    background: #093fce;
    box-shadow: 0 0 0 3px #edf2f7;
  }

  &.awaiting_result {
    background: #ed9373;
    box-shadow: 0 0 0 3px #ffdede;
  }

  &.fully_paid {
    background: #1aa551;
    box-shadow: 0 0 0 3px #eafbf1;
  }

  &.not_paid {
    background: red;
    box-shadow: 0 0 0 3px #ffdede;
  }

  &.not-paid {
    background: red;
    box-shadow: 0 0 0 3px #ffdede;
  }

  &.partially_paid {
    background: #ed9373;
    box-shadow: 0 0 0 3px #ffdede;
  }

  &.paid {
    background: #1aa551;
    box-shadow: 0 0 0 3px #eafbf1;
  }

  &.result_ready {
    background: #093fce;
    box-shadow: 0 0 0 3px #eafbf1;
  }

  &.sent {
    background: #1aa551;
    box-shadow: 0 0 0 3px #eafbf1;
  }

  &.not_sent {
    background: red;
    box-shadow: 0 0 0 3px #ffdede;
  }

  &.partially_sent {
    background: #ed9373;
    box-shadow: 0 0 0 3px #ffdede;
    
  }  &.visited {
    background: #4C65A9;
    box-shadow: 0 0 0 3px #eafbf1;
  } 
}