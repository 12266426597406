.homepage {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;

  &__searchWrapper {
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
  }
}

h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #364469;
  margin: 20px 0px;
}

.userFoundContainer {
  text-align: center;

  &__caption {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #83889d;
    margin: 20px 0px;
  }
}

.authSection {
  width: 400px;
  height: 100%;
  text-align: center;
  margin: 10px auto 0px auto;

  &__heading {
    color: #0b2253;
    padding-top: 20px;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }

  &__instruction {
    color: #425466;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

.icon {
  float: right;
}

.tableContainer {
  width: 70% !important;
  height: 420px;
  overflow-y: scroll;
  margin: 30px auto auto auto;
}

.priceCalculator {
  display: grid;
  grid-template-columns: 1fr 1fr 1.2fr;
  grid-gap: 30px;
  align-items: center;
  margin: 50px;
  height: 100%;

  &__selectContainer {
    height: 300px;
    margin-top: -35px;
  }

  &__selectFilterOption {
    display: flex;
    justify-content: space-between;
  }

  &__summary {
    width: 90%;
    height: 300px;
    background: #fafafa;
    border-radius: 8px;
    padding: 20px;

    h3 {
      margin: 0;
    }
  }

  &__summaryContainer {
    height: 210px;
    margin: 10px 0px;
    overflow-y: scroll;
  }

  &__summaryContainer_flex {
    display: flex;
    gap: 10px 20px;
    flex-wrap: wrap;
    width: 100%;
  }

  &__summaryItem {
    background: #edf2f7;
    border-radius: 16px;
    margin-top: 10px;
    padding: 10px;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    text-transform: capitalize;

    &__value {
      color: #83889d;
      font-size: 12px;
    }

    &__icon {
      padding-left: 10px;
    }
  }

  &__emptyState {
    text-align: center;
    margin: 50px 0px;
  }
}

.totalSummary {
  display: flex;
  justify-content: space-between;
  margin: 20px 265px;

  p {
    margin: 0;
  }

  &__text {
    font-weight: 500;
    font-size: 14px;
    color: #b5b5bd;
    padding-top: 5px;
  }

  &__amount {
    font-weight: 700;
    font-size: 20px;
    color: #364469;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
  }
}

.input {
  width: 72px;
  height: 28px;
  background: #fafafa;
  opacity: 0.8;
  border-radius: 4px;
  border: none;
  color: #000000;
}

.input:focus-visible {
  border: 1px solid #edf2f7 !important;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1%;

  h1 {
    font-size: 28px;
    color: #364469;
  }
}

.detailWrapper {
  height: 100%;
  background: #ffffff;
  border: 1px solid #edf2f7;
  border-radius: 4px;
  padding: 24px 20px;
}

.detailSheet {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 12px;
  grid-row-gap: 50px;

  &__text {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #83889d;
  }

  &__value {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.02em;
    word-break: break-word;
    color: #364469;
    text-transform: capitalize;
  }
}

.package {
  &__layout {
    width: 450px;
    margin: auto;
  }

  &__layout2 {
    width: 650px;
    margin: auto;
  }

  &__procedureWrapper {
    height: 260px;
    background: #fafafa;
    border-radius: 8px;
    overflow-y: scroll;
    padding: 20px 10px 20px 20px;
    margin-top: 40px;
  }

  &__packageDate {
    padding-left: 20px;
  }

  &__procedureItem {
    display: flex;
    justify-content: space-between;
    gap: 10;
    height: 50px;
    align-items: center;
    text-transform: capitalize;
    color: #364469;
  }
}

.filterBtn {
  display: flex;
  align-items: center;

  p {
    margin: 0;
  }
}

.indicatorWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  text-transform: capitalize;
}

.formWrapper {
  display: flex;
  justify-content: center;
  padding: 3% 0% 0% 0%;

  &__form {
    width: 400px;
    margin: auto;
  }

  &__input {
    padding-top: 6%;
  }

  &__input2 {
    padding: 6% 0px;
  }

  &__flexInput {
    display: flex;
    justify-content: space-between;
    padding-top: 5%;
    gap: 15px;
  }

  &__forgotPasswordText {
    display: flex;
    justify-content: flex-end;
    position: relative;
    color: #ed9373;
    font-weight: 600;
    font-size: 14px;
    padding: 15px 0px;
    right: -7%;
  }

  &__btn {
    background: none;
    border: none;
    color: #ed9373;
    font-weight: 500;
    cursor: pointer;
  }

  &__button {
    padding-top: 6%;
  }
}

.formlayout {
  &__form {
    width: 400px;
    margin: auto;
  }

  &__heading {
    display: flex;
    justify-content: space-between;
  }
}

.chooseProcedure {
  margin: 30px;
  display: flex;
  justify-content: space-between;

  &__title {
    font-size: 24px;
    color: #0b2253;
    line-height: 32px;
    text-align: center;
    text-transform: capitalize;
  }

  &__description {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #425466;
    padding: 50px 0px 20px 0px;
  }

  &__priceTypeInput {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  &__selectContainer {
    width: 50%;
    height: 300px;
    margin: -35px 30px 0px 0px;
  }

  &__summary {
    width: 50%;
    height: 300px;
    background: #fafafa;
    border-radius: 8px;
    padding: 20px;

    h3 {
      margin: 0;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 610px;
    height: 60px;
    position: absolute;
    right: 0%;
    top: 0%;
    align-items: center;
    padding-right: 50px;

    h2,
    .icon {
      padding: 10px 0px 0px 50px;
    }

    .icon {
      cursor: pointer;
    }
  }
}