.instruction {
  font-weight: 400;
  font-size: 16px;
  color: #83889d;
  padding-bottom: 20px;
}

p {
  margin: 5px 0px;
}

.formWrapper {
  margin-top: 20px;
  width: 578px;

  input {
    width: 260px;
  }
}

.profile {
  &img__modal {
    width: 400px;
    height: fit-content;
  }

  &__img__modal {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
  }

  @-webkit-keyframes zoom {
    from {
      -webkit-transform: scale(0);
    }

    to {
      -webkit-transform: scale(1);
    }
  }

  @keyframes zoom {
    from {
      transform: scale(0);
    }

    to {
      transform: scale(1);
    }
  }

  &__edit__confirm {
    display: flex;
    justify-content: space-around;
    gap: 10%;

    button {
      border: 1px solid #b5b5bd;
      border-radius: 8px;
      color: #425466;
      width: 192px;
      box-sizing: border-box;
      height: 48px;
      padding: 12px 80px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
    }
  }

  &__reset__form {
    margin-top: 70px;

    &__child {
      padding: 2%;
    }
  }

  &__close {
    position: absolute;
    left: 23rem;
    z-index: 2;
    cursor: pointer;
  }

  &__form {
    display: flex;
    justify-content: space-between;
    padding: 2% 0%;
  }

  &__btn {
    margin-top: 10px;
    float: right;
  }

  &__show_dialog {
    cursor: pointer;

    input {
      display: none;
    }

    button {
      text-decoration: underline;
      font-weight: 500;
      font-size: 18px;
      color: #27272e;
      background: none;
      border: none;
      cursor: pointer;
      position: absolute;
      left: 10rem;
      padding-top: 3%;
    }
  }

  &__card {
    padding: 24px;
    margin-top: 6rem;
    background: #fafafa;
    border-radius: 8px;
    max-width: 578px;
    height: 130px;

    h2 {
      color: #83889d;
      font-weight: 600;
      font-size: 20px;
    }

    h3 {
      font-weight: 500;
      font-size: 16px;
      color: #425466;
    }

    &__sibling {
      display: flex;
      justify-content: space-between;

      &__text {
        font-weight: 400;
        font-size: 14px;
        color: #83889d;
      }

      h4 {
        text-decoration: underline;
        color: #ed9373;
        font-weight: 600;
        font-size: 16px;
        cursor: pointer;
        position: relative;
        bottom: 2.6rem;
      }
    }
  }
}

.close__head {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 464px;
  height: 80px;
  position: absolute;
  right: 0%;
  top: 0%;
  background: #fafafa;

  h2,
  .icon {
    padding: 25px 0px 0px 50px;
  }

  .icon {
    cursor: pointer;
  }
}

.upload {
  border: 1px dashed #093fce;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 40px;
  width: 65%;

  &__text {
    font-size: 14px;
    line-height: 16px;
    color: #425466;
  }

  &__file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #CA1B1B;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__filled_state {
    display: flex;
    align-items: flex-start;
    padding: 10px 20px;
  }

  &__empty_state {
    display: flex;
    align-items: flex-start;
    padding: 22px 20px;

    p {
      margin: 0 0 0 16px;
      display: flex;
      flex-direction: row;
    }

    &__file {
      color: #ed9373;
      font-weight: 600;
      font-size: 16px;
    }

    &_caption {
      font-weight: 400;
      font-size: 16px;
      color: #83889d;
      line-height: 20px;
    }

    &_csv {
      color: #83889d;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      margin-top: 4px !important;
    }
  }
}

.textarea {
  p {
    margin-right: 12px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }

  textarea {
    padding: 6px;
    border: 1px solid #EDF2F7;
    border-radius: 4px;
    background: #FAFAFA;
    outline: none;
    min-height: 20px;
    max-height: 100px;
  }
}