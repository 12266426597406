.main {
  &__layout {
    padding: 24px;
    flex: 1;
    display: flex;
    height: 100%;
    column-gap: 2%;
    background: #fafafa;
  }

  &__page {
    width: 73%;
    min-height: 100vh;
    position: relative;
    left: 22%;
    background: #ffffff;
    border-radius: 16px;
    padding: 24px 32px;
  }
}

.formlayout {
  &__container {
    padding: 24px;
    background: #fafafa;
  }

  &__content {
    background-color: white;
    min-height: 80vh;
    padding: 60px 0;
  }

  &__button {
    padding: 0 12%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    button {
      display: flex;
      align-items: center;
    }
  }

  &__form {
    width: 400px;
    margin: auto;

    h2 {
      text-align: center;
    }

    &__input {
      padding-top: 6%;
    }

    &_button {
      margin-top: 24px;
    }

    &__flexInput {
      display: flex;
      justify-content: space-between;
      padding: 2% 0%;
    }
  }

  &__form2 {
    width: 30%;
    margin: auto;

    h2 {
      text-align: center;
    }
  }

  &__title {
    font-size: 24px;
    color: #0b2253;
    line-height: 32px;
    text-align: center;
    text-transform: capitalize;
  }

  &__description {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #425466;
    margin-bottom: 10px;
  }
}

.homepage {
  &__actionbox {
    display: flex;
    width: unset;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 24px;
    margin-top: 1.5rem;

    button {
      width: unset;
    }
  }

  &__date {
    margin-right: 16px;
  }

}

.pieChartWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 440px;
  margin-bottom: 2px;
  margin-top: 4rem;



  &__wrapper1 {
    width: 35%;
    border: 1px solid #edf2f7;
    border-radius: 8px;
    padding: 20px;

    p {
      margin: 8px 0px;
    }

    h1 {
      font-weight: 600;
      font-size: 32px;
      margin: 0;
      color: #27272e;
    }

    &__text {
      font-weight: 400;
      font-size: 14px;
      color: #425466;
      width: 50%;
      text-transform: capitalize;
    }

    &__amount {
      font-weight: 600;
      font-size: 16px;
      color: #425466;
      text-align: left;
      justify-content: flex-start;
    }

    &__header {
      font-size: 14px;
      font-weight: 700;
      color: #83889d;
      margin-bottom: 10px;
    }

    &__total {
      font-size: 14px;
      color: #425466;

      span {
        color: #b5b5bd;
        padding-left: 2px;
      }
    }

    &__transaction_method {
      background: #fafafa;
      border-radius: 4px;
      padding: 10px;
      margin-top: 30px;
      height: 200px;
    }

    &__transactionFlex {
      display: flex;
      justify-content: space-between;
      padding-top: 5px;
      width: 100%;
    }

    &__TransactionWrapper {
      height: 150px;
      overflow-y: scroll;
    }
  }

  &__wrapper2 {
    width: 53%;
    border: 1px solid #edf2f7;
    border-radius: 8px;
    padding: 20px;

    h2 {
      font-weight: 600;
      font-size: 20px;
      color: #364469;
      margin: 0px;
    }
  }

  &__doughnutDetailsWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 2rem;
    top: 0%;
    left: 50%;
    transform: translate(-10%, 0%);
  }

  &__doughnuttextwrapper {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    grid-column-gap: 30px;
    grid-row-gap: 20px;
    overflow-y: scroll;
    height: 220px;
    margin-left: 20px;
    width: 50%;

    p {
      margin: 5px 0px;
    }

    &__flexWrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      top: 0%;
      left: 0%;
      transform: translate(0%, 15%);

      &_item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & img {
          margin-right: 10px;
        }
      }
    }
  }

  &__room {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.01em;
    color: #83889d;
    text-transform: capitalize;
    width: 100px;
  }

  &__amount {
    display: flex;
    flex-direction: row;
    font-weight: 600;
    font-size: 16px;
    color: #27272e;

    span {
      font-size: 12px;
      color: #83889d;
      padding-left: 3px;
    }
  }

}


.totalRevenue {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(-10%, -50%);
  text-align: center;

  h2 {
    font-weight: 600;
    font-size: 20px;
    color: #27272e;
  }

  &__caption {
    font-weight: 500;
    font-size: 12.0494px;
    color: #425466;
    margin-left: 10px;
  }
}